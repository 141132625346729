import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import Layout from "../components/layout"
import NewsPhoto from "../images/news-photo.jpg"
import SEO from "../components/seo"
import { PageHeaderText, InfoHeader, Info, Blue, Red } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${NewsPhoto});
  background-size: cover;
  display: flex;
`
const InfoInside = styled.div`
  width: 80%;
  height: auto;
  margin: auto;
`
const NoLink = styled.span`
  text-decoration: underline;
  -webkit-text-decoration-color: #74b9ff;
  text-decoration-color: #74b9ff;
`
const InfoContainer1 = styled(Grid)`
  height: 40vh;
  background: #0d0d0d;
  width: 100%;
  display: flex;
`
const BigContainer = styled(Grid)`
  height: 55vh;
  background: #0d0d0d;
  width: 100%;
  display: flex;
`
const InfoContainer2 = styled(Grid)`
  height: 40vh;
  background: #181818;
  width: 100%;
  display: flex;
`

const Community = () => (
  <Layout>
    <SEO title="Community" />
    <Grid container>
      <TitleImage item xs={12}>
        <PageHeaderText>Community</PageHeaderText>
      </TitleImage>
      <BigContainer item xs={12}>
        <InfoInside>
          <InfoHeader>
            Sussex Outreach Services <Blue>(SOS)</Blue>
          </InfoHeader>
          <Info>
            Sussex Outreach Services (SOS) is the social ministry arm of the
            Cooperating Churches of Sussex (CCOS) and served 489 family units in
            2013, that were seeking food, clothing, counseling, educational
            training, preventive health programming and referrals. We serve all
            families and individuals in the Hamilton School District, which
            includes Sussex, the Town of Lisbon, and parts of Merton. We are
            currently averaging 325 family unit visits a month. It is the
            mission of Sussex Outreach Services (S0S), to meet the immediate and
            long term needs of individuals and families in the Hamilton School
            District by providing food, informational resources, client advocacy
            and general assistance. SOS builds community awareness and
            volunteerism to encourage our community members to help support our
            mission in their own unique way. To improve lives and build strong
            community awareness through: supportive listening, awareness,
            assistance, and advocacy.
          </Info>
          <Info>
            <Red>
              More information about SOS may be found on the{" "}
              <a href="https://www.sussexareasos.org/" rel="noopener noreferrer" target="_blank">
                <Blue>
                  <NoLink>SOS Website</NoLink>
                </Blue>
              </a>
            </Red>
          </Info>
        </InfoInside>
      </BigContainer>
      <InfoContainer2>
        <InfoInside>
          <InfoHeader>
            <Blue>Cooperating Churches of Sussex</Blue>
          </InfoHeader>
          <Info>
            At St. Alban’s we think of our service our neighbors as a ministry
            of welcome and evangelism. We are a charter member of the
            Cooperating Churches of Sussex
          </Info>
          <Info>
            The Cooperating Churches of Sussex is an ecumenical group of 6
            churches;
            <Red>
              {" "}
              Christ Our Savior Lutheran, Redeemer U.C.C., Lisbon Presbyterian,
              Sussex United Methodist, St. James Roman Catholic & St. Alban’s
              Episcopal which serves the Village of Sussex, Town of Lisbon and
              adjacent areas.{" "}
            </Red>
          </Info>
        </InfoInside>
      </InfoContainer2>
      <InfoContainer1>
        <InfoInside>
          <InfoHeader>
            <Blue>Community Banquet</Blue>
          </InfoHeader>
          <Info>
            The Community Banquet is a meal program for those in need in the
            Sussex Area. It is sponsored by the Cooperating Churches of Sussex
            and is hosted at St. James Church. We participate on a rotating
            schedule with several other churches in the area.
          </Info>
        </InfoInside>
      </InfoContainer1>
    </Grid>
  </Layout>
)

export default Community
